import { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignIn from './Components/SignIn/SignIn';
import SignUp from './Components/SignUp/SignUp';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import Courier from './Components/Courier/Courier';
import { Route, Routes } from 'react-router-dom';

// Conditional redirect component
const RedirectIfLoggedIn = ({ children, redirectUrl }) => {
  // Check if the user is logged in directly from localStorage
  const isLoggedIn = localStorage.getItem('userEmail') && localStorage.getItem('userToken');

  if (isLoggedIn) {
    // If logged in, redirect to the given URL (external)
    window.location.href = redirectUrl;
    return null;
  }

  return children; // If not logged in, show the children (component)
};

function App() {
  const [userEmail, setUserEmail] = useState(null);
  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    const storedUserEmail = localStorage.getItem('userEmail');
    const storedUserToken = localStorage.getItem('userToken');
    const storedTimestamp = localStorage.getItem('timestamp');

    const currentTimestamp = new Date().getTime();
    const expiryTime = 1 * 24 * 60 * 60 * 1000;
    if (storedTimestamp && (currentTimestamp - storedTimestamp > expiryTime)) {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('userToken');
      localStorage.removeItem('timestamp');
      console.log('LocalStorage items cleared due to expiration.');
    } else if (storedUserEmail && storedUserToken) {
      setUserEmail(storedUserEmail);
      setUserToken(storedUserToken);
    }
  }, []);

  // Set redirect URL
  const redirectUrl = `https://apps.lateshipment.com/dashboard?lsemail=${userEmail}&lstoken=${userToken}`;

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <RedirectIfLoggedIn redirectUrl={redirectUrl}>
              <SignIn />
            </RedirectIfLoggedIn>
          }
        />

        <Route
          path="/login"
          element={
            <RedirectIfLoggedIn redirectUrl={redirectUrl}>
              <SignIn />
            </RedirectIfLoggedIn>
          }
        />

        <Route
          path="/signup"
          element={
            <RedirectIfLoggedIn redirectUrl={redirectUrl}>
              <SignUp />
            </RedirectIfLoggedIn>
          }
        />

        {/* Reset password page is accessible regardless of login status */}
        <Route path="/resetpassword" element={<ForgotPassword />} />

        <Route
          path="/courier"
          element={
            <RedirectIfLoggedIn redirectUrl={redirectUrl}>
              <Courier />
            </RedirectIfLoggedIn>
          }
        />
      </Routes>
    </div>
  );
}

export default App;


// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import SignIn from './Components/SignIn/SignIn';
// import SignUp from './Components/SignUp/SignUp';
// import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
// import Courier from './Components/Courier/Courier';
// import { Route, Routes, Navigate } from 'react-router-dom';


// function App() {


//   return (
//     <div className="App">
//       <Routes>
//         <Route
//           path="/"
//           element={
//             <SignIn />
//           }
//         />

//         <Route
//           path="/signup"
//           element={
//             <SignUp />
//           }
//         />

//         {/* Reset password page is accessible regardless of login status */}
//         <Route path="/resetpassword" element={<ForgotPassword />} />

//         <Route
//           path="/courier"
//           element={
//             <Courier />
//           }
//         />
//       </Routes>
//     </div>
//   );
// }

// export default App;
