import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Nav, Tab } from 'react-bootstrap';
import { Check, Mail, CircleHelp } from 'lucide-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Courier.css';
import { Link, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom

const Courier = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const notify = (type) => {
        const message = type === 'error' ? "Carrier account creation failed. Please try again." : "Carrier account(s) created successfully!";
        const backgroundColor = 'rgb(1, 27, 69)';
        const progressColor = 'linear-gradient(240deg, rgba(0, 184, 199, 0.1), rgba(1, 27, 69, 0.1))'; // Keep the same progress style
        const toastType = type === 'error' ? toast.error : toast.success;

        toastType(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
                background: backgroundColor, // Dynamic background color
                color: '#fff', // White text
                borderRadius: '8px', // Rounded corners
                fontSize: '16px', // Adjust font size if needed
                padding: '12px 20px', // Adjust padding
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
            },
            progressStyle: {
                background: progressColor, // Same gradient progress bar
            },
        });
    };

    const obj = [
        { Company_name: "OnTrac", image: "OnTrac.jpeg", carrier_id: 9 },
        { Company_name: "ParcelForce", image: "Paecel Force.jpeg", carrier_id: 8 },
        { Company_name: "Royal Mail", image: "Royal Mail.jpeg", carrier_id: 5 },
        { Company_name: "TNT", image: "Tnt.jpeg", carrier_id: 3 },
        { Company_name: "UPS", image: "Ups.jpeg", carrier_id: 2 },
        { Company_name: "YODEL", image: "Yodel.jpeg", carrier_id: 1 }
    ];


    const [companyData, setCompanyData] = useState([]);
    const [checkList, setCheckList] = useState([]);
    const [formData, setFormData] = useState({});
    const [activeKey, setActiveKey] = useState("all");
    const [activeStep, setActiveStep] = useState(1);  // Step state to track the current step

    const totalSteps = 2;

    useEffect(() => {
        setCompanyData(obj);
    }, []);

    // Formik validation schema for each company's username and password
    const generateValidationSchema = () => {
        const schema = {};
        checkList.forEach(company => {
            schema[`${company.Company_name}_username`] = Yup.string().required('Username is required');
            schema[`${company.Company_name}_password`] = Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .required('Password is required');
        });
        return Yup.object().shape(schema);
    };


    const generateInitialValues = () => {
        const values = {};
        checkList.forEach(company => {
            values[`${company.Company_name}_username`] = '';
            values[`${company.Company_name}_password`] = '';
        });
        return values;
    };

    // Submit Function
    const submit = async (values, { resetForm }) => {
        try {
            setLoading(true);
            const signupPayload = JSON.parse(localStorage.getItem('signupPayload'));

            const carriersData = checkList.map((company) => ({
                carriername: company.carrier_id,  // Use the carrier ID from the obj array
                username: values[`${company.Company_name}_username`],
                password: btoa(values[`${company.Company_name}_password`]), // Base64 encode the password
            }));

            const fullPayload = {
                ...signupPayload,
                carriers: carriersData,
            };

            const response = await fetch('https://smart.lateshipment.com/Genericwebhook/accountcreationapi.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Key': process.env.REACT_APP_X_Auth_Key,
                    'X-Auth-email': process.env.REACT_APP_X_Auth_email,
                },
                body: JSON.stringify(fullPayload),
            });

            if (!response.ok) {
                throw new Error('Carrier account creation failed');
            }

            const result = await response.json();

            if (result.status === 'success' && result.message.includes('Carrier created')) {
                notify('success');
                resetForm();
                navigate('/');
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error("Error during carrier account creation:", error);
            notify('error');
        } finally {
            setLoading(false);  // Reset loading after request completion
        }
    };


    const formik = useFormik({
        enableReinitialize: true, // Important when initialValues change dynamically
        initialValues: generateInitialValues(),
        validationSchema: generateValidationSchema(),
        onSubmit: submit
    });

    // Handle Company Selection
    const handleSelect = (companyName, image) => {
        setCheckList(prevList => {
            const company = obj.find(item => item.Company_name === companyName);  // Find the company from the obj array
            const carrierData = {
                ...company,  // Include all company data including carrier_id
                image,
            };

            const updatedList = prevList.some(item => item.Company_name === companyName)
                ? prevList.filter(item => item.Company_name !== companyName)  // Remove company if deselected
                : [...prevList, carrierData];  // Add the selected company with carrier_id

            setFormData(updatedList);
            return updatedList;
        });
    };

    // Handle input change for each company
    const handleInputChange = (e, company) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [company]: {
                ...prevData[company],
                [name]: value
            }
        }));
    };

    // Handle navigation to the next page (Selected Logos)
    const handleNext = () => {
        if (activeStep < totalSteps) {
            setActiveStep(activeStep + 1);  // Move to the next step
            setActiveKey("selected");
        }
    };

    // Handle navigation back to the all logos page
    const handleBack = () => {
        if (activeStep > 1) {
            setActiveStep(activeStep - 1);  // Move to the previous step
            setActiveKey("all");
        }
    };

    // Apply styles to the Next button
    const nextButtonStyle = checkList.length === 0 ? { backgroundColor: '#d3d3d3', cursor: 'not-allowed' } : {};

    return (
        <React.Fragment>
            <ToastContainer position="top-right" autoClose={3000} />
            <div className='login_par'>
                <Row className='m-0 h-100 align-items-center justify-content-center'>
                    <Col xxl={9} xl={9} sm={9} className='shadow-lg rounded-4 p-0 overflow-hidden'>
                        <Row className='m-0'>
                            <Col lg={5} className='d-lg-flex d-none p-0 theme_bg py-5 align-items-center'>
                                <div className='w-100'>
                                    <div className='img_box bg-white text-center mx-auto rounded-5 py-5 px-5'>
                                        <img src={require("../../Images/login_front.png")} className='w-100 cover' alt="" />
                                    </div>
                                    <div className='txt mt-5'>
                                        <p className='m-0 text-white text-center fw-bold f_18'>
                                            Shipping Solutions designed <br />
                                            to save you money, time, and hassle.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={7} className='py-5 px-2 pb-3 bg-white'>
                                <div>
                                    <div className='logo_box mx-auto'>
                                        <img src={require('../../Images/logo.png')} className='w-100 h-100 object-fit-contain' alt="" />
                                    </div>
                                    <div className='mt-5 text-center'>
                                        <h3 className='login_txt fw-bolder dark_clr mb-3'>
                                            Which of these carriers do you ship with?
                                        </h3>
                                        <p className='m-0 px-2'>
                                            We connect with one or more of your carrier accounts to audit your shipping invoices, file refund claims on your behalf, provide real-time visibility and shipping intelligence.
                                        </p>
                                    </div>

                                    {/* Step Progress Bar */}
                                    <div className='my-3'>
                                        <div className="step-progress-container">
                                            {[...Array(totalSteps)].map((_, index) => (
                                                <div
                                                    key={index}
                                                    className={`step ${index < activeStep ? 'completed' : ''} ${index === activeStep - 1 ? 'active' : ''}`}
                                                />
                                            ))}
                                        </div>
                                        <div className="step-text mt-2 text-center">
                                            <span>Step {activeStep} of {totalSteps}</span>
                                        </div>
                                    </div>

                                    {/* Tabs for All and Selected Logos */}
                                    <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                                        <Row className='m-0'>
                                            <Col sm={12}>
                                                <Tab.Content>
                                                    {/* All Logos Tab */}
                                                    <Tab.Pane eventKey="all">
                                                        <div className='img_box row justify-content-center m-0 w-100'>
                                                            {companyData.map((ele, index) => (
                                                                <div
                                                                    key={index}
                                                                    className={`Clogo_box pointer-cursor ${checkList.some(item => item.Company_name === ele.Company_name) ? 'selected' : ''}`}
                                                                    onClick={() => handleSelect(ele.Company_name, ele.image)}
                                                                >
                                                                    <img src={require(`../../Images/${ele.image}`)} className='rounded-circle' alt="" />
                                                                    <p className='m-0 mt-2'>{ele.Company_name}</p>
                                                                    {checkList.some(item => item.Company_name === ele.Company_name) && (
                                                                        <div className='icn_box'>
                                                                            <Check className='text-success tick_icn' />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className='my-3 text-center text-sm-end me-lg-5'>
                                                            <Link to={"/"}> <button type="button" className="theme_btn px-3 py-2">Skip</button></Link>
                                                            <button
                                                                type="button"
                                                                className="theme_btn px-3 py-2 ms-2"
                                                                onClick={handleNext}
                                                                style={nextButtonStyle} // Apply style based on checkList length
                                                                disabled={checkList.length === 0} // Disable Next button if no logos selected
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </Tab.Pane>

                                                    {/* Selected Logos Tab */}
                                                    <Tab.Pane eventKey="selected">
                                                        <div className='selected-logos fixed-height'>
                                                            {checkList.map((company, idx) => (
                                                                <div key={idx} className='selected-logo-item mb-4'>
                                                                    <div>
                                                                        <img src={require(`../../Images/${company.image}`)} height={"55px"} width={"55px"} alt={company.Company_name} />
                                                                        <span className='ms-3'>{company.Company_name}</span>
                                                                    </div>
                                                                    <hr className='m-0 my-2 mb-4' />
                                                                    <div>
                                                                        <label>Username</label>
                                                                        <input
                                                                            type="text"
                                                                            name={`${company.Company_name}_username`}
                                                                            value={formik.values[`${company.Company_name}_username`] || ''}
                                                                            className={`input_controll w-100 rounded-2 p-2 ${formik.errors[`${company.Company_name}_username`] && formik.touched[`${company.Company_name}_username`]
                                                                                ? 'input_border'
                                                                                : ''
                                                                                }`}
                                                                            placeholder='Enter your username'
                                                                            // onChange={(e) => handleInputChange(e, company.Company_name)}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                    </div>
                                                                    {formik.errors[`${company.Company_name}_username`] && formik.touched[`${company.Company_name}_username`] && (
                                                                        <span className='text-danger'>{formik.errors[`${company.Company_name}_username`]}</span>
                                                                    )}

                                                                    <div className='mt-1'>
                                                                        <label>Password</label>
                                                                        <input
                                                                            type="password"
                                                                            name={`${company.Company_name}_password`}
                                                                            value={formik.values[`${company.Company_name}_password`] || ''}
                                                                            className={`input_controll w-100 rounded-2 p-2 ${formik.errors[`${company.Company_name}_password`] && formik.touched[`${company.Company_name}_password`]
                                                                                ? 'input_border'
                                                                                : ''
                                                                                }`} placeholder='Enter your password'
                                                                            // onChange={(e) => handleInputChange(e, company.Company_name)}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                    </div>
                                                                    {formik.errors[`${company.Company_name}_password`] && formik.touched[`${company.Company_name}_password`] && (
                                                                        <span className='text-danger'>{formik.errors[`${company.Company_name}_password`]}</span>
                                                                    )}                                                                </div>
                                                            ))}
                                                        </div>
                                                        <form onSubmit={formik.handleSubmit}>
                                                            <div className='my-3 text-center text-sm-end me-lg-5'>
                                                                <button type="button" className="theme_btn px-3 py-2" onClick={handleBack}>Back</button>
                                                                <button
                                                                    type="submit"
                                                                    className="theme_btn px-3 py-2 ms-2"
                                                                    disabled={loading} // Disable button while loading
                                                                >
                                                                    {loading ? (
                                                                        <div className="spinner-border spinner-border-sm text-light" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div>
                                                                    ) : (
                                                                        'Submit'
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default Courier;
