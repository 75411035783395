import { useFormik } from 'formik';
import { Mail } from 'lucide-react';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import loginFront from '../../Images/login_front.png';
import logo from '../../Images/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {

    const [loading, setLoading] = useState(false);


    const notify = (type) => {
        const message = type === 'error' ? "The email id does not exists" : "Sent you an email with a link to reset your password";
        const backgroundColor = 'rgb(1, 27, 69)';
        const progressColor = 'linear-gradient(240deg, rgba(0, 184, 199, 0.1), rgba(1, 27, 69, 0.1))'; // Keep the same progress style
        const toastType = type === 'error' ? toast.error : toast.success;

        toastType(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
                background: backgroundColor, // Dynamic background color
                color: '#fff', // White text
                borderRadius: '8px', // Rounded corners
                fontSize: '16px', // Adjust font size if needed
                padding: '12px 20px', // Adjust padding
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
            },
            progressStyle: {
                background: progressColor, // Same gradient progress bar
            },
        });
    };

    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid Email Address").required("Email is required")
    });

    const initialValues = {
        email: ''
    };

    const submit = async (values, { resetForm }) => {
        try {
            setLoading(true);
            const response = await fetch('https://smart.lateshipment.com/Genericwebhook/forgotpasswordapi.php', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Key': process.env.REACT_APP_X_Auth_Key,
                    'X-Auth-email': process.env.REACT_APP_X_Auth_email,
                    'X-Auth-Customeremail': values.email, // user-entered email
                },
            });

            if (!response.ok) {
                throw new Error('Failed to authenticate user');
            }

            const result = await response.json();

            if (result.status === 'success' && result.msg !== ' The email id does not exists') {
                notify('success');
                resetForm();
            } else {
                throw new Error('Invalid email or token');
            }
        } catch (error) {
            console.error('Login error:', error);
            notify('error');
        } finally {
            setLoading(false);  // Reset loading after the request is finished
        }
    };

    const { handleBlur, handleChange, handleSubmit, touched, errors, values } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: submit
    });

    return (
        <React.Fragment>

            <ToastContainer position="top-right" autoClose={3000} />

            <div className='login_par'>
                <Row className='m-0 h-100 align-items-center justify-content-center'>
                    <Col xxl={8} xl={9} lg={10} md={6} sm={8} className='shadow-lg rounded-4 p-0 overflow-hidden'>
                        <Row className='m-0'>
                            <Col lg={7} className='d-lg-block d-none p-0 theme_bg py-5 d-flex align-items-center'>
                                <div className='w-100'>
                                    <div className='img_box bg-white text-center mx-auto rounded-5 py-5 px-5'>
                                        <img src={loginFront} className='w-100 cover' alt="Forgot Password" />
                                    </div>
                                    <div className='txt mt-5'>
                                        <p className='m-0 text-white text-center fw-bold f_18'>
                                            Shipping Solutions designed <br />
                                            to save you money, time, and hassle.
                                        </p>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={5} className='py-5 p-0 bg-white'>
                                <div>
                                    <div className='logo_box mx-auto'>
                                        <img src={logo} className='w-100 h-100 object-fit-contain' alt="Logo" />
                                    </div>
                                    <div className='mt-5 text-center'>
                                        <h3 className='login_txt fw-bolder dark_clr mb-3'>Reset Password</h3>
                                        <p className='m-0 mx-3'>
                                            We'll send an email with instructions to reset your password.
                                        </p>
                                    </div>

                                    <div className='px-sm-5 px-3 mt-4'>
                                        <form className='w-100' onSubmit={handleSubmit}>
                                            <div className='my-3'>
                                                <label className='d-block mb-1' htmlFor="email">Email</label>
                                                <div className={`input_box d-flex align-items-center ${errors.email && touched.email ? "input_border" : ""}`}>
                                                    <Mail className='input_icns' />
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={values.email}
                                                        className='input_controlls'
                                                        placeholder='Enter your email'
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                {errors.email && touched.email && (
                                                    <span className='text-danger'>{errors.email}</span>
                                                )}
                                            </div>

                                            <div className='my-3 mt-4'>
                                                <button
                                                    type='submit'
                                                    className='theme_btn w-100'
                                                    disabled={loading} // Disable button while loading
                                                >
                                                    {loading ? (
                                                        <div className="spinner-border spinner-border-sm text-light" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        'Submit'
                                                    )}
                                                </button>
                                            </div>

                                            <div className='my-3 text-center'>
                                                <p className='m-0'>
                                                    Remember your credentials? <Link to='/' className='light_clr text-decoration-none'>Sign in</Link>
                                                </p>
                                            </div>

                                            <hr />

                                            <div className='my-3 text-center'>
                                                <p className='m-0'>
                                                    Don't have an account? <Link to='/signup' className='light_clr text-decoration-none'>Sign Up</Link>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </React.Fragment>

    );
};

export default ForgotPassword;
